import slugify from 'slugify';

export const downloadFileViaApi = async (fileName, action, params = {}) => {
  if (!fileName) return false;

  const fileNameSlug = slugify(fileName, {
    replacement: ' ',
    lower: false
  });

  const file = await action({
    ...params,
    data: {
      ...(params.data || {}),
      params: {
        fileName: fileNameSlug,
        ...((params.data && params.data.params) || {})
      }
    }
  });

  const downloadUrl = file.signedUrl || file.downloadUrl || file.previewUrl || file.url;
  console.log(downloadUrl);
  if (!downloadUrl) return false;

  const link = document.createElement('a');

  link.target = '_blank';
  link.href = downloadUrl;
  link.download = fileNameSlug;
  link.click();

  URL.revokeObjectURL(link.href);
};
